/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ThemeSwitcher from "./theme-switcher"
import LogoDark from "../images/logo-dark.png"
import LogoWhite from "../images/logo-white.png"
import * as domUtils from "../utils/dom"

const Navbar = ({ siteTitle, hasThemeSwitcher }) => {
  const [documentElement, setDocumentElement] = useState(null)

  useEffect(() => {
    const topWindow = (window && window.top) || {}
    const document = (topWindow && topWindow.document) || {}
    const documentElm = (document && document.documentElement) || {}

    setDocumentElement(documentElm)
  }, [])

  const handleButtonClick = e => {
    e.preventDefault()
    const menuButton = document.querySelector(".menu-button")
    if (!documentElement || !menuButton) {
      return
    }
    domUtils.toggleClass(documentElement, "overflow")
    domUtils.toggleClass(menuButton, "expanded")
  }

  return (
    <div className="navbar">
      <Link className="brand-item" to="/">
        <img src={LogoDark} className="logo logo-dark" width="65px" alt={siteTitle} />
        <img src={LogoWhite} className="logo logo-white" width="65px" alt={siteTitle} />
      </Link>
      <ul className="navbar-items left desktop-menu">
        <li className="item">
          <Link activeClassName="active" to="/projets/">
            Projets
          </Link>
        </li>
        <li className="item">
          <Link activeClassName="active" to="/blog/">
            Blog
          </Link>
        </li>
        <li className="item">
          <Link activeClassName="active" to="/a-propos/">
            A propos
          </Link>
        </li>
        {hasThemeSwitcher === true ? (
          <li>
            <ThemeSwitcher />
          </li>
        ) : (
          ""
        )}
      </ul>
      <button type="button" aria-label="menu" className="menu-button" onClick={handleButtonClick}>
        <div className="line top" />
        <div className="line bottom" />
      </button>
      <div className="mobile-menu">
        <ul className="navbar-items">
          <li className="item" data-animation-delay="70">
            <Link activeClassName="active" to="/">
              Accueil
            </Link>
          </li>
          <li className="item" data-animation-delay="140">
            <Link activeClassName="active" to="/projets/">
              Projets
            </Link>
          </li>
          <li className="item" data-animation-delay="210">
            <Link activeClassName="active" to="/blog/">
              Blog
            </Link>
          </li>
          <li className="item" data-animation-delay="280">
            <Link activeClassName="active" to="/a-propos/">
              A propos
            </Link>
          </li>
          {hasThemeSwitcher === true ? (
            <li>
              <ThemeSwitcher />
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    </div>
  )
}

export default Navbar

Navbar.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  hasThemeSwitcher: PropTypes.bool,
}

Navbar.defaultProps = {
  hasThemeSwitcher: false,
}
