import React from "react"
import PropTypes from "prop-types"
import Navbar from "./navbar"

const Header = ({ siteTitle, hasThemeSwitcher }) => <Navbar siteTitle={siteTitle} hasThemeSwitcher={hasThemeSwitcher} />

export default Header

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  hasThemeSwitcher: PropTypes.bool,
}

Header.defaultProps = {
  hasThemeSwitcher: false,
}
