import * as numberUtils from "./number"
/**
 * Get current device
 *
 * @returns {string} device
 */
export const getDevice = () => {
  const topWindow = typeof window !== "undefined" && window.top

  if(!topWindow) {
    return
  }
  const ua = topWindow.navigator.userAgent

  const width =
    topWindow.innerWidth || topWindow.document.documentElement.clientWidth || topWindow.document.body.clientWidth
  // enable responsive even if not device detected
  // based on width
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) || numberUtils.between(width, 728, 991)) {
    return "tablet"
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(
      ua
    ) ||
    numberUtils.between(width, 0, 727)
  ) {
    return "mobile"
  }
  return "desktop"
}
