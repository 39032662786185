/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import Footer from "../components/footer"
import Particles from "../components/particles"

import "../fonts/avenir-next/style.css"
import "./scss/index.scss"

import * as deviceUtils from "../utils/device"
import * as domUtils from "../utils/dom"

const Layout = ({ children, hasParticles, hasThemeSwitcher, cssClass }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const { siteMetadata } = site
  const [globalWindow, setGlobalWindow] = useState(null)
  const [documentElement, setDocumentElement] = useState(null)

  useEffect(() => {
    const topWindow = (window && window.top) || {}
    const document = (topWindow && topWindow.document) || {}
    const documentElm = (document && document.documentElement) || {}

    setGlobalWindow(topWindow)
    setDocumentElement(documentElm)

    const removeExtraClasses = () => {
      if (documentElm) domUtils.removeClass(documentElm, "overflow")
    }
    const checkDevice = () => {
      const body = document.querySelector("body")
      if (body) body.setAttribute("data-device", deviceUtils.getDevice())
    }

    const loadOnInit = () => {
      removeExtraClasses()
      topWindow.addEventListener("resize", checkDevice)
    }

    loadOnInit()
  }, [])

  return (
    <>
      <Helmet
        title={siteMetadata.title}
        meta={[{ name: "description", content: "Sample" }, { name: "keywords", content: "sample, something" }]}
      >
        <body className={cssClass} data-device={deviceUtils.getDevice()} />
        <html lang="fr" />
      </Helmet>
      {hasParticles === true ? <Particles /> : ""}
      <div className="container">
        <Header siteTitle={siteMetadata.title} hasThemeSwitcher={hasThemeSwitcher} />
        {children}
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasParticles: PropTypes.bool,
  hasThemeSwitcher: PropTypes.bool,
  cssClass: PropTypes.string,
}

Layout.defaultProps = {
  hasParticles: false,
  hasThemeSwitcher: true,
  cssClass: "",
}

export default Layout
