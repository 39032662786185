module.exports = {
  title: "Laurent Panek",
  description: "description",
  siteURL: "https://laurentpanek.me",
  socialMedia: {
    twitter: "https://twitter.com/laurentPanek",
    github: "https://github.com/Laurent-PANEK",
    linkedin: "https://www.linkedin.com/in/laurent-panek",
    email: "mailto:contact@laurentpanek.me",
  },
  author: {
    name: "Laurent Panek",
    avatar: "",
    bio: "",
  },
  postsPerPage: 10,
  postsIncrementBy: 5,
}
