/**
 * Checks if HTML element has css class
 *
 * @param {HTMLElement} el
 * @param {string} className
 */
export const hasClass = (el, className) => {
  if (!el || !className) return false
  return el.className.match(className)
}

/**
 * Removes css class for a given HTML element
 *
 * @param {HTMLElement} el
 * @param {string} className
 */
export const removeClass = (el, className) => {
  if (hasClass(el, className)) {
    el.className = el.className.replace(className, "").trim()
  }
}

/**
 * Add css class for a given HTML element
 *
 * @param {HTMLElement} el
 * @param {string} className
 */
export const addClass = (el, className) => {
  if (!hasClass(el, className)) {
    el.className += ` ${className}`
  }
}

/**
 * Toggles css class for a given HTML element
 *
 * @param {HTMLElement} el
 * @param {string} className
 */
export const toggleClass = (el, className) => {
  if (el && el.className.indexOf(className) !== -1) {
    removeClass(el, className)
  } else {
    addClass(el, className)
  }
}
