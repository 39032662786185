/**
 * Checks if value is between two numbers
 *
 * @param {*} value
 * @param {*} a
 * @param {*} b
 * @param {boolean} [inclusive=true]
 * @returns
 */
export const between = (value, a, b, inclusive = true) => {
  const min = Math.min.apply(Math, [a, b])
  const max = Math.max.apply(Math, [a, b])
  return inclusive ? value >= min && value <= max : value > min && value < max
}
